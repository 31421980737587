import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    profile_plan_discount: [],
    profile_plan: {},
    header: "Client Profile",
    subHeader: "Compare our plans and choose the one that's right for you.",
    planChat: [], // Array of chat objects { userPrompt, aiResponse }
    viewType: "Vertical",
    planPreviewEnable: false,
    profileExtraFields: {
        images: true,
        brands: 'New Brand',
        versions: 'v1.0',
        draft: 'completed',
        icons: false,
    },
    planCurrentPlanIndex: 0, // Add this to track current index
};

const resetStates = {
    profile_plan_discount: [],
    profile_plan: {},
    planChat: [], // Array of chat objects { userPrompt, aiResponse }
    planPreviewEnable: false,
    profileExtraFields: {
        images: true,
        brands: 'New Brand',
        versions: 'v1.0',
        draft: 'completed',
        icons: false,
    },
    planCurrentPlanIndex: 0,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfilePlanDiscount: (state, action) => {
            state.profile_plan_discount = action.payload;
        },
        setProfilePlan: (state, action) => {
            state.profile_plan = action.payload;
        },
        setProfileView: (state, action) => {
            state.viewType = action.payload;
        },
        setProfilePlanHeader: (state, action) => {
            state.header = action.payload;
        },
        setProfilePlanSubHeader: (state, action) => {
            state.subHeader = action.payload;
        },
        setPlanChat: (state, action) => {
            state.planChat = action.payload;
        },
        setPlanCurrentPlanIndex: (state, action) => {
            state.planCurrentPlanIndex = action.payload;
        },
        setPlanPreviewEnable: (state, action) => {
            state.planPreviewEnable = action.payload;
        },
        setsubCustomer: (state, action) => {
            state.subCustomer = action.payload;
        },
        setProfileExtraFields: (state, action) => {
            state.profileExtraFields = action.payload;
        },
        setResetProfileFields: (state, action) => {
            return { ...state, ...resetStates }
        }
    },
});

export const {
    setProfilePlanDiscount,
    setProfilePlan,
    setProfilePlanHeader,
    setProfilePlanSubHeader,
    setPlanChat,
    setResetProfileFields,
    setPlanCurrentPlanIndex,
    setPlanPreviewEnable,
    setsubCustomer,
    setProfileView,
    setProfileExtraFields,
} = profileSlice.actions;

export default profileSlice.reducer;
