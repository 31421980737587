import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fetchUrl from "../../api/index";
import toast from "react-hot-toast";

let configObj = { baseUrl: process.env.REACT_APP_URL, contentType: "application/json" };

// Fetch Reports
export const fetchReports = createAsyncThunk(
  'reports/fetch',
  async () => {
    const type = 'get';
    const url = '/report/scheduler/list/';
    try {
      const response = await fetchUrl({ type, url, config: configObj });

      if (response) {
        return response;
      } else {
        throw new Error("Failed to fetch reports");
      }
    } catch (error) {
      toast.error("Failed to fetch reports. Please try again.");
      throw error;
    }
  }
);

// Add Reports
export const addReports = createAsyncThunk(
  'reports/add',
  async (data, { dispatch }) => {
    const type = 'post';
    const url = `/report/scheduler/add/`;
    try {
      const response = await fetchUrl({ type, url, data, config: configObj });

      if (response?.status === 201) {
        toast.success("Report created successfully.");
        dispatch(fetchReports());
        return response.data;
      } else {
        toast.error("Please enter valid scheduler name and try again.");
        throw new Error("Failed to create report");
      }
    } catch (error) {
      toast.error("Please enter valid scheduler name and try again.");
      throw error;
    }
  }
);

// Delete Reports
export const deleteReports = createAsyncThunk(
  'reports/delete',
  async (data, { dispatch }) => {
    const type = 'delete';
    const url = `/report/scheduler/${data.scheduler_id}/`;
    try {
      const response = await fetchUrl({ type, url, data: null, config: configObj });

      if (response.status === 204) {
        toast.success("Report deleted successfully!");
        dispatch(fetchReports());
        return data;
      } else {
        throw new Error("Failed to delete report");
      }
    } catch (error) {
      toast.error("Failed to delete report. Please try again.");
      throw error;
    }
  }
);

// Update Reports
export const updateReports = createAsyncThunk(
  'reports/update',
  async (data, { dispatch }) => {
    const type = 'put';
    const url = `/report/scheduler/${data.scheduler_id}/`;
    try {
      const response = await fetchUrl({ type, url, data: data.body, config: configObj });

      if (response.status === 200) {
        toast.success("Report updated successfully!");
        dispatch(fetchReports());
        return data;
      } else {
        throw new Error("Failed to update report");
      }
    } catch (error) {
      toast.error("Failed to update report. Please try again.");
      throw error;
    }
  }
);

// Utility functions for setting state
const setLoading = (state) => {
  state.status = 'loading';
  state.statuscode = 2;
  state.queryexecute = [];
  state.aiquerystr = [];
};
const setSucceeded = (state) => {
  state.status = 'succeeded';
  state.statuscode = 1;
};
const setFailed = (state, action) => {
  state.status = 'failed';
  state.statuscode = 0;
  state.error = action.error.message;
};

// Define the initial state
const initialState = {
  data: [],
  status: 'idle',
  statuscode: 0,
  queryexecute: [],
  aiquerystr: '',
  aiissuesstr: '',
  querysettitle: '',
  aiissueslist: [],
  error: null,
};

// Create slice
const querysetsSlice = createSlice({
  name: 'querysets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Report Sets
      .addCase(fetchReports.pending, setLoading)
      .addCase(fetchReports.fulfilled, (state, action) => {
        setSucceeded(state);
        state.data = action.payload;
      })
      .addCase(fetchReports.rejected, setFailed)
      // Add Report Sets
      .addCase(addReports.pending, setLoading)
      .addCase(addReports.fulfilled, setSucceeded)
      .addCase(addReports.rejected, setFailed)
      // Delete Report Sets
      .addCase(deleteReports.pending, setLoading)
      .addCase(deleteReports.fulfilled, (state, action) => {
        setSucceeded(state);
        state.data.results = state.data?.results.filter((item) => item.id !== action.payload.query_set_id);
      })
      .addCase(deleteReports.rejected, setFailed)
      // Update Report Sets
      .addCase(updateReports.pending, setLoading)
      .addCase(updateReports.fulfilled, setSucceeded)
      .addCase(updateReports.rejected, setFailed);
  }
});

export default querysetsSlice.reducer;
