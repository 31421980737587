import React, { lazy } from 'react';

const MainLayout = lazy(() => import("./layout/index"));

const Dashboard = lazy(() => import("./pages/Dashboard/index"));

const Category = lazy(() => import("./pages/Category/index"));
const CategoryForm = lazy(() => import("./pages/Category/CategoryForm"));

const Product = lazy(() => import("./pages/Product/index"));
const ProductForm = lazy(() => import("./pages/Product/ProductForm"));

const Inventory = lazy(() => import("./pages/Inventory"));

const Orders = lazy(() => import("./pages/Order/index"));
const OrderDetails = lazy(() => import("./pages/Order/OrderDetails"));



const ecommerceRoutes = [
    {
        id: 'route-ecommerce',
        name: 'E-commerce',
        path: 'e-commerce',
        element: <MainLayout />,
        children: [
            {
                id: 'route-dashboard',
                name: 'Dashboard',
                path: 'dashboard',
                element: <Dashboard />,
            },
            {
                id: 'route-category',
                name: 'Category',
                path: 'category',
                element: <Category />,
            },
            {
                id: 'route-add-category',
                name: 'Add Category',
                path: 'category/add-category',
                element: <CategoryForm />,
            },
            {
                id: 'route-edit-category',
                name: 'Edit Category',
                path: 'category/edit-category/:id',
                element: <CategoryForm />,
            },
            {
                id: 'route-products',
                name: 'Products',
                path: 'products',
                element: <Product />,
            },
            {
                id: 'route-add-product',
                name: 'Add Product',
                path: 'products/add-product',
                element: <ProductForm />,
            },
            {
                id: 'route-edit-product',
                name: 'Edit Product',
                path: 'products/edit-product/:id',
                element: <ProductForm />,
            },
            {
                id: 'route-inventory',
                name: 'Inventory',
                path: 'inventory',
                element: <Inventory />,
            },
            {
                id: 'route-orders',
                name: 'Orders',
                path: 'orders',
                element: <Orders />,
            },
            {
                id: 'route-order-details',
                name: 'Order Details',
                path: 'orders/:orderId',
                element: <OrderDetails />,
            },
        ],
    },
];

export default ecommerceRoutes;
